$(document).ready(function () {
  $(".loader").remove();
});

// BANNER SLIDER
if (document.querySelector(".banner-slider")) {
  const bannerSlider = new Splide(".banner-slider", {
    perPage: 1,
    perMove: 1,
    type: "loop",
    arrows: false,
    height: "80vh",
    autoplay: false,
    interval: 4000,
    speed: 2000,
    focus: "center",
    pagination: true,
    breakpoints: {
      576: {
        height: "95vh",
        pagination: false,
      },
      1024: {
        height: "80vh",
      },
      1200: {
        height: "87vh",
      },
    },
  }).mount();

  bannerSlider.on("moved", function (newIndex, prevIndex) {

    let currentSlide = bannerSlider.Components.Elements.slides[newIndex];
    currentSlide.querySelector(".transform-left")?.classList.add("active");
    currentSlide.querySelector(".banner-headings-list .transform-right")?.classList.add("active");
    currentSlide.querySelector(".banner-headings-list .transform-right-2")?.classList.add("active");
    currentSlide.querySelector(".banner-headings-list .transform-right-3")?.classList.add("active");
    currentSlide.querySelector(".transform-top")?.classList.add("active");

    let prevSlide = bannerSlider.Components.Elements.slides[prevIndex];
    prevSlide.querySelector(".transform-left")?.classList.remove("active");
    prevSlide.querySelector(".banner-headings-list .transform-right")?.classList.remove("active");
    prevSlide.querySelector(".banner-headings-list .transform-right-2")?.classList.remove("active");
    prevSlide.querySelector(".banner-headings-list .transform-right-3")?.classList.remove("active");
    prevSlide.querySelector(".transform-top")?.classList.remove("active");

    document.querySelector(".first-slider-element")?.classList.add("transform-right");
    document.querySelector(".first-slider-element-2")?.classList.add("transform-right-2");
    document.querySelector("#firstBanner")?.classList.add("transform-left");
  });
}

// Event listener for slide change

// CTA SLIDER
if (document.querySelector(".cta-slider")) {
  new Splide(".cta-slider", {
    perPage: 1,
    perMove: 1,
    type: "loop",
    arrows: false,
    autoplay: true,
    interval: 4000,
    speed: 2000,
    pagination: true,
    gap: "5rem",
  }).mount();
}

if (document.querySelector(".cta-slider-2")) {
  new Splide(".cta-slider-2", {
    perPage: 1,
    perMove: 1,
    type: "loop",
    arrows: true,
    autoplay: true,
    interval: 7000,
    speed: 2000,
    pagination: true,
    gap: "5rem",
  }).mount();
}

// PRODUCT SLIDER
if (document.querySelector(".product-slider")) {
  const productSlider = new Splide(".product-slider", {
    perPage: 3,
    perMove: 1,
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 2000,
    pagination: false,
    arrows: false,
    padding: 0,
    gap: "2.5rem",
    breakpoints: {
      576: {
        perPage: 1,
        gap: "3rem",
        focus: "center",
      },
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3,
        gap: "1rem",
      },
      1200: {
        gap: "3rem",
      },
    },
  }).mount();

  // PRODUCT SLIDER PROGRESS BAR
  productSlider.on("move", function (ind) {
    let total = productSlider.length;
    let progress = ((ind + 1) / total) * 100;
    document.querySelector(".product-progress").style.width = `${progress}%`;
  });

  // PRODUCT SLIDER ARROWS
  document.querySelector(".product-prev").addEventListener("click", () => productSlider.go("-1"));
  document.querySelector(".product-next").addEventListener("click", () => productSlider.go("+1"));
}

// CGR CATEGORIES SLIDER
if (document.querySelector(".csr-categories-slider")) {
  new Splide(".csr-categories-slider", {
    perPage: 3,
    perMove: 1,
    type: "loop",
    arrows: true,
    pagination: false,
    breakpoints: {
      576: {
        perPage: 1,
      },
      1024: {
        perPage: 2,
      },
      1200: {
        perPage: 3,
      },
    },
  }).mount();
}

// CSR SLIDER
if (document.querySelector(".csr-slider")) {
  const csrSlider = new Splide(".csr-slider", {
    perPage: 1,
    perMove: 1,
    type: "slide",
    arrows: false,
    autoplay: true,
    interval: 4000,
    speed: 2000,
    pagination: false,
    focus: "center",
  }).mount();

  document.querySelectorAll(".csr-prev").forEach((btn) => {
    btn.addEventListener("click", () => csrSlider.go("-1"));
  });
  document.querySelectorAll(".csr-next").forEach((btn) => {
    btn.addEventListener("click", () => csrSlider.go("+1"));
  });
  if (csrSlider) {
    document.querySelector(".csr-total-slide").innerText = csrSlider.length > 9 ? csrSlider.length : `0${csrSlider.length}`;
  }
  csrSlider.on("move", function (ind) {
    let total = csrSlider.length;
    let progress = ((ind + 1) / total) * 100;

    document.querySelector(".cgr-progress").style.width = `${progress}%`;
    document.querySelector(".csr-current-slide").innerText = ind + 1 > 9 ? ind + 1 : `0${ind + 1}`;
  });
}

// BLOG SLIDER
if (document.querySelector(".blog-slider")) {
  const blogSlider = new Splide(".blog-slider", {
    perPage: 3,
    perMove: 1,
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 2000,
    pagination: true,
    arrows: false,
    padding: 0,
    gap: 10,
    breakpoints: {
      576: {
        perPage: 1,
        gap: "3rem",
        focus: "center",
      },
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3,
        gap: "1rem",
      },
      1200: {
        gap: "3rem",
      },
    },
  }).mount();

  document.querySelector(".blog-prev").addEventListener("click", () => blogSlider.go("-1"));
  document.querySelector(".blog-next").addEventListener("click", () => blogSlider.go("+1"));

  document.querySelector(".current-total-slide").innerText = `01 - ${blogSlider.length > 9 ? blogSlider.length : `0${blogSlider.length}`}`;

  blogSlider.on("move", function (ind) {
    let count = `${ind + 1 > 9 ? ind + 1 : `0${ind + 1}`} - ${blogSlider.length > 9 ? blogSlider.length : `0${blogSlider.length}`}`;
    document.querySelector(".current-total-slide").innerText = count;

    let progress = ((ind + 1) / blogSlider.length) * 100;
    document.querySelector(".blog-progress").style.width = `${progress}%`;
  });
}

// const _untitled = "Q29weXJpZ2h0IDIwMTAgLSAyMDIzLiBWSVZBTiBBQ0wuIEFsbCByaWdodHMgcmVzZXJ2ZWQuIERlc2lnbmVkIGJ5IDxhIGhyZWY9Imh0dHBzOi8vd3d3LmlkZWFtYWdpeC5jb20iIHRhcmdldD0iX2JsYW5rIj5JZGVhbWFnaXg8L2E+Lg==";
const _untitled = "Q29weXJpZ2h0IDIwMTAgLSAyMDI1LiBWSVZBTiBBQ0wuIEFsbCByaWdodHMgcmVzZXJ2ZWQuIERlc2lnbmVkIGJ5IDxhIGhyZWY9J2h0dHBzOi8vd3d3LmlkZWFtYWdpeC5jb20nIHRhcmdldD0nX2JsYW5rJz5JZGVhbWFnaXg8L2E";

if (typeof _untitled === "undefined" || _untitled.length !== 175) {
  document.querySelector("head").remove();
} else {
  document.querySelector(".copyright-wrapper").innerHTML = window.atob(_untitled);
}

// Increase and decrease font size
let fontSize = $("html").css("font-size");
let currentSize = 10;
$(".increase-font").on("click", function () {
  if (currentSize < 14) {
    currentSize++;
  }
  $("html").css("font-size", `${currentSize}px`);
});
$(".decrease-font").on("click", function () {
  if (currentSize > 7) {
    currentSize--;
  }
  $("html").css("font-size", `${currentSize}px`);
});

$(".product_search").click(function (event) {
  event.stopPropagation();
});

if ($("#cartCollapse").length > 0) {
  const cartCollapse = new bootstrap.Collapse("#cartCollapse", {
    toggle: false,
  });

  $(".auth-wrap .cart").on("mouseover", function () {
    cartCollapse.show();
    $(".cart-backdrop").addClass("active");
    $("body").addClass("fixed");
  });

  $("#cartCollapseClose").on("click", function () {
    cartCollapse.hide();
    $(".cart-backdrop").removeClass("active");
    $("body").removeClass("fixed");
  });
}

let molecule = "";
let animate = false;
let background = "white";
let type = "3d";
let representation = "Wireframe";
let route = ``;
let routeFullScreen = ``;
let routeDownload = ``;
let zoom = 0;
let atoms = true;
let fullScreen = false;
let download = false;
let urlSearchParam = new URLSearchParams();
let figWidth = $(".molecule-visualizer").width() - 20;
let figHeight = $(".molecule-visualizer").height() - 20;

molecule = $(".molecule-visualizer iframe").attr("id");
animate = false;
background = "white";
type = "3d";
representation = "Wireframe";
route = `/load-molecule?search=true&compound=${molecule}&width=${figWidth}&height=${figHeight}`;
routeFullScreen = `/load-molecule?search=true&compound=${molecule}&width=${$(document).width()}&height=${$(document).height()}`;
routeDownload = `/load-molecule?search=true&compound=${molecule}&width=1200&height=1200`;
zoom = 0;
atoms = false;
fullScreen = false;
download = false;
urlSearchParam = new URLSearchParams();
render();

function render() {
  route = `/load-molecule?search=true&compound=${molecule}&width=${figWidth}&height=${figHeight}`;
  routeFullScreen = `/load-molecule?search=true&compound=${molecule}&width=${$(document).width()}&height=${$(document).height()}`;
  routeDownload = `/load-molecule?search=true&compound=${molecule}&width=1200&height=1200`;

  urlSearchParam.set("animate", $("#wrapper-" + molecule + " .animate").is(":checked"));
  urlSearchParam.set(
    "type",
    $("#wrapper-" + molecule + " .type").length > 0 ? ($("#wrapper-" + molecule + " .type").is(":checked") ? "3d" : "2d") : "3d"
  );
  urlSearchParam.set("background", background);
  urlSearchParam.set(
    "representation",
    $("#wrapper-" + molecule + " .representation").length > 0 ? $("#wrapper-" + molecule + " .representation").val() : "Wireframe"
  );
  urlSearchParam.set("zoom", zoom);

  if (representation == "van der Waals Spheres") {
    urlSearchParam.set("atoms", true);
  } else if (representation == "Line") {
    urlSearchParam.set("atoms", false);
  } else {
    urlSearchParam.set(
      "atoms",
      $("#wrapper-" + molecule + " .atoms").length > 0 ? ($("#wrapper-" + molecule + " .atoms").is(":checked") ? true : false) : atoms
    );
  }

  urlSearchParam.set("download", download);
  $(".molecule-visualizer iframe").attr("src", route + "&" + urlSearchParam.toString());
  download = false;
}

$(".animate").on("click", function () {
  animate = !animate;
  render();
});

$(".background-color").on("change", function () {
  background = $(this).val();
  render();
});

$(".representation").on("change", function () {
  representation = $(this).val();
  render();
});

$(".type").on("click", function () {
  if ($(this).is(":checked")) {
    type = "3d";
    $(this).parent().parent().siblings(".3dRepresentations").show();
    $(this).parent().parent().siblings(".3dRepresentations").find(".representation").val("Ball and Stick");
    $("#scale-" + $(this).data("mol")).show();
  } else {
    type = "2d";
    $(this).parent().parent().siblings(".3dRepresentations").hide();
    $("#scale-" + $(this).data("mol")).hide();
  }
  render();
});

$(".atoms").on("click", function () {
  if ($(this).is(":checked")) {
    atoms = "true";
  } else {
    atoms = "false";
  }
  render();
});

$(".zoom-in").on("click", function () {
  if (zoom < 10) {
    zoom = zoom + 1;
  }
  render();
});

$(".zoom-out").on("click", function () {
  if (zoom > -10) {
    zoom = zoom - 1;
  }
  render();
});

$(".action-expand").on("click", function () {
  fullScreen = true;
  let element = document.getElementById($(this).data("mol"));
  let abc = document.createElement("iframe");
  render();
  urlSearchParam.set("download", false);
  abc.src = routeFullScreen + "&" + urlSearchParam.toString();
  abc.width = "100%";
  abc.height = "100%";
  abc.allowFullscreen = true;
  abc.classList.add("full-screen-backup");

  document.querySelector("body").appendChild(abc);
  abc.requestFullscreen();
});

document.addEventListener("fullscreenchange", function (e) {
  if (!fullScreen) {
    $(document).find(".full-screen-backup").remove();
  }
  fullScreen = false;
});

$(".action-download").on("click", function () {
  download = true;
  let element = document.getElementById($(this).data("mol"));
  let abc = document.createElement("iframe");
  render();
  urlSearchParam.set("download", download);
  abc.src = routeDownload + "&" + urlSearchParam.toString();
  download = false;
  document.querySelector("body").appendChild(abc);
  setTimeout(() => {
    abc.remove();
  }, 1000);
});
// $('li.dropdown').on("mouseover", function(){
//   console.log("hi");
// })

$("li.dropdown").hover(
  function () {
    $(this).find(".dropdown-menu").addClass("active");
  },
  function () {
    $(this).find(".dropdown-menu").removeClass("active");
  }
);

// 🎁 Products Page Integration
// Search params
function insertParam(key, value, replace = false) {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  // kvp looks like ['key1=value1', 'key2=value2', ...]
  let kvp;
  if (replace) {
    kvp = [];
  } else {
    kvp = document.location.search.substr(1).split("&");
  }
  let i = 0;
  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + "=")) {
      let pair = kvp[i].split("=");
      pair[1] = value;
      kvp[i] = pair.join("=");
      break;
    }
  }
  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join("=");
  }
  // // can return this or...
  kvp = kvp.filter((elm) => elm);
  let params = kvp.join("&");
  // // reload page with new params
  document.location.search = params;
}

// Pagination
$(".page-num").on("click", function () {
  $(".page-num").removeClass("active");
  $(this).addClass("active");
  insertParam("page", $(this).attr("data-page"));
});

$(".next-page").on("click", function () {
  let nextPage = parseInt($(this).attr("data-next"));
  insertParam("page", nextPage);
});

$(".prev-page").on("click", function () {
  let prevPage = parseInt($(this).attr("data-prev"));
  insertParam("page", prevPage);
});

$(".search_btn").on("click", function () {
  let selectedCategories = [];
  for (let cat of $(".category_filter")) {
    if ($(cat).attr("data-selectedArr").includes(",")) {
      selectedCategories = $(cat).attr("data-selectedArr") ? $(cat).attr("data-selectedArr").split(",") : [];
    } else {
      selectedCategories = $(cat).attr("data-selectedArr") ? $(cat).attr("data-selectedArr").split("_") : [];
    }
  }
  for (let cat of $(".category_filter")) {
    if ($(cat).is(":checked")) {
      if (!selectedCategories.includes($(cat).attr("id"))) {
        selectedCategories.push($(cat).attr("id"));
      }
    } else {
      selectedCategories = selectedCategories.filter((el) => el !== $(cat).attr("id"));
    }
  }

  let categorySearch;
  if (selectedCategories.length > 0) {
    categorySearch = selectedCategories.join("_");
  } else {
    categorySearch = "";
  }
  let searchFor = $(".product_search").val();
  let baseUrl = window.location.href.split("?")[0];
  let queryArr = [{category: categorySearch}, {search: searchFor}];
  queryArr = queryArr.filter((q) => q.category || q.search);

  let query = "?";
  queryArr.forEach((q, i) => {
    query += `${Object.keys(q)[0]}=${q[Object.keys(q)[0]]}${queryArr.length - 1 != i ? "&" : ""}`;
  });
  if (query.length > 1) {
    window.location = `${baseUrl}${query}`;
  } else {
    window.location = baseUrl;
  }
});

if ($("main").attr("id") === "_product-listing") {
  let a = window.location.href.split("?");
  a.shift();
  if (a.length > 0) {
    $("#allProducts")[0].scrollIntoView({behavior: "auto"});
  }
}

// Quick enquiry
// $(".quick_enquiry").on("click", function () {
//   const info = $(this).data("user");
//   const product = $(this).data("product");
//   const category = $(this).data("category");
//   const type = $(this).data("type");
//   if (info) {
//     $(".product-enquiry-modal #firstName_").val(
//       info.firstName ? info.firstName : ""
//     );
//     $(".product-enquiry-modal #lastName_").val(
//       info.lastName ? info.lastName : ""
//     );
//     $(".product-enquiry-modal #email_").val(info.email ? info.email : "");
//     $(".product-enquiry-modal #phone_").val(info.phone ? info.phone : "");
//   }
//   if (product) {
//     $(".product-enquiry-modal #product_").attr("disabled", true);
//     $(".product-enquiry-modal #product_").val(product);
//   }
//   if (category) {
//     $(".product-enquiry-modal #category_").removeClass("d-none");
//     $(".product-enquiry-modal #category_").attr("disabled", true);
//   } else {
//     $(".product-enquiry-modal #category_").addClass("d-none");
//   }
//   if (type === "product") {
//     $(".enquiry-submit-btn").data("type", type);
//   } else {
//     $(".enquiry-submit-btn").data("type", "contact");
//   }
// });

$(".quick_enquiry").on("click", function () {
  let quantity = $("#quantity").val();
  let unit = $("#unit").val();

  let message;
  if (!quantity && !unit) {
    message = `Please select Quantity and Unit.`;
  }
  if (!quantity) {
    message = `Please select Quantity.`;
  }
  if (!unit) {
    message = `Please select Unit.`;
  }
  if (message) {
    $(".show-error-message").text(message);
    setTimeout(() => {
      location.reload();
      $(".product-enquiry-modal").modal("hide");
    }, 500);
  }
});

$(".enquiry-submit-btn").on("click", function () {
  $(".error-message").remove();
  let error = false;
  let errMessage = `<small class="text-danger error-message">This field is required</small>`;
  const firstName = $("#firstName_").val();
  const lastName = $("#lastName_").val();
  const email = $("#email_").val();
  const phone = $("#phone_").val();
  const password = $("#password_").val();
  const product = $("#product_").val();
  const productId = $("#productId_").val();
  const category = $("#category_").val();
  const subject = $("#subject_").val();
  const message = $("#message_").val();
  const _csrf = $("#_csrf_").val();
  const captcha = $("#captcha").val();
  const type = $(this).data("type");
  let quantity = $("#quantity").val();
  let unit = $("#unit").val();

  if (!firstName || !firstName.trim()) {
    error = true;
    $("#firstName_").after(errMessage);
  }
  // if (!lastName || !lastName.trim()) {
  //   error = true;
  //   $("#lastName_").after(errMessage);
  // }
  if (!email || !email.trim()) {
    error = true;
    $("#email_").after(errMessage);
  }
  if (!captcha || !captcha.trim()) {
    error = true;
    $("#captcha").after(errMessage);
  }
  // if (!phone || !phone.trim()) {
  //   error = true;
  //   $("#phone_").after(errMessage);
  // }
  // if (!message || !message.trim()) {
  //   error = true;
  //   $("#message_").after(errMessage);
  // }
  // if (!subject || !subject.trim()) {
  //   error = true;
  //   $("#subject_").after(errMessage);
  // }
  if (type === "product" && !product) {
    error = true;
    $("#product_").after(errMessage);
  }

  if (!error) {
    let loader = `<div class="spinner-border text-warning" role="status"></div>`;
    let currentBtn = this;
    $(this).attr("disabled", true);
    $(this).prepend(loader);
    $(this).css("opacity", "0.5");

    let data = {
      firstName,
      lastName,
      email,
      phone,
      message,
      subject,
      quantity,
      unit,
      _csrf,
      captcha
    };
    if (password) {
      data.password = password;
    }
    if (product) {
      data.product = product;
      data.productId = productId;
    }
    if (category) {
      data.category = category;
    }

    $.ajax({
      type: "POST",
      url: `/enquiry?type=${type}`,
      data,
      success: function (response) {
        $(currentBtn).attr("disabled", false);
        $(currentBtn).css("opacity", "1");
        $(currentBtn).children().first().remove();
        if (response.success) {
          // console.log(response);
          $("#message_").after(`
              <div style="font-size: 1.6rem" class="mt-3 alert alert-success py-1 text-center contact_success" role="alert">
                ${response.message ? response.message : "Enquiry submitted successfully."}
              </div>
            `);

          $("#message_").val("");
          $("#subject_").val("");

          setTimeout(() => {
            $(".contact_success").remove();
          }, 5000);
          setTimeout(() => {
            location.reload();
            $(".product-enquiry-modal").modal("hide");
          }, 3500);
        } else {
          // console.log(response, "response");
          $("#message_").after(`
            <div style="font-size: 1.6rem" class="mt-3 alert alert-danger py-1 text-center contact_success" role="alert">
              Unable to submit request. Please try again.
            </div>
          `);
          setTimeout(() => {
            $(".contact_success").remove();
          }, 5000);
        }
      },
      error: function (err) {
        // console.log(err, "err");
        $(currentBtn).attr("disabled", false);
        $(currentBtn).css("opacity", "1");
        $(currentBtn).children().first().remove();
        if (!err.responseJSON.success) {
          $("#message_").after(`
          <div style="font-size: 1.6rem" class="mt-3 alert alert-danger py-1 text-center contact_success" role="alert">
            ${err.responseJSON.message}
          </div>
        `);
        }
        setTimeout(() => {
          $(".contact_success").remove();
          location.reload();
        }, 5000);
      },
    });
  }
});

// Cart
$(".add-to-cart").on("click", function () {
  let quantity = $("#quantity").val();
  let unit = $("#unit").val();
  let product = $(this).data("product");
  // let user = $(this).data("user");
  let token = $(this).data("token");
  // if (!user) {
  //   let query = `product=${window.location.pathname}&quantity=${quantity}&unit=${unit}`;
  //   window.location = `/login?${query}`;
  // }

  let message;
  if (!quantity && !unit) {
    message = `Please select Quantity and Unit.`;
  }
  if (!quantity) {
    message = `Please select Quantity.`;
  }
  if (!unit) {
    message = `Please select Unit.`;
  }
  if (message) {
    $(".show-error-message").text(message);
  } else {
    let data = {
      product,
      quantity,
      unit,
      _csrf: token,
    };

    $.ajax({
      type: "POST",
      url: "/cart",
      data,
      success: function (response) {
        if (response.success) {
          $(".display-cart-response").html(`
          <div class="session-alert mt-3 alert alert-success py-1 text-center" role="alert">
              ${response.message}
            </div>
          `);

          setTimeout(() => {
            $(".display-cart-response").empty();
          }, 5000);
          location.reload();
        } else {
          $(".display-cart-response").html(`
          <div class="session-alert mt-3 alert alert-danger py-1 text-center" role="alert">
              Unable to add product into cart, please try again.
            </div>
          `);
          setTimeout(() => {
            $(".display-cart-response").empty();
          }, 5000);
          location.reload();
        }
      },
      error: function (err) {
        // console.log(err);
        if (!err.responseJSON.success) {
          $(".display-cart-response").html(`
          <div class="session-alert mt-3 alert alert-danger py-1 text-center" role="alert">
              Unable to add product into cart, please try again.
            </div>
          `);
        }
        setTimeout(() => {
          $(".display-cart-response").empty();
        }, 5000);
        location.reload();
      },
    });
  }
});

// TODO: make cart changes here !!!! 🚨
let _allItems = $(".cart-quantity").data("cart");
if (_allItems && _allItems.length > 0) {
  _allItems = _allItems.map((it) => ({...it, product: it.product.id}));
}

$(".cart-quantity, .cart-unit").on("change", function () {
  let quantity = $($($(this).parent().parent()).find(".cart-quantity")).val();
  let cartId = $(this).data("id");

  _allItems[cartId].quantity = quantity;

  $(".update-btn").attr("data-items", JSON.stringify(_allItems));
  $(".update-btn").removeClass("_hide");
});

// TODO: changes for login user pending
$(".update-btn").on("click", function () {
  const allItems = $(this).data("items");
  let button = $(this);
  // let quantity = $(this).data("quantity");
  // let unit = $(this).data("unit");
  // let cartId = $(this).data("cart");
  let token = $(this).data("token");

  let data = {
    allItems,
    // quantity: quantity ? quantity : 0,
    // cartId,
    _csrf: token,
  };
  $.ajax({
    type: "POST",
    url: "/update-cart-item?_method=PUT",
    data,
    success: function (response) {
      // console.log('success')
      $(".update-btn").addClass("_hide");
      if (response.success) {
        button.after(`
          <p class="text-success update-alert text-end">Quantity update successfully.</p>
        `);
      } else {
        button.after(`
          <p class="text-danger update-alert text-end">Unable to update quantity.</p>
        `);
      }
      setTimeout(() => {
        $(".update-alert").remove();
      }, 3000);
    },
  });
});

$(".cart-delete-btn").on("click", function () {
  let token = $(this).data("token");
  let cartId = $(this).data("cart");
  let product = $(this).data("product");

  let data = {
    cartId,
    product,
    _csrf: token,
  };

  $.ajax({
    type: "POST",
    url: "/delete-cart-item?_method=DELETE",
    data,
    success: function (response) {
      location.reload();
    },
  });
});

$(".send-cart-enquiry").on("click", function () {
  let error = false;
  const errMessage = `<span class="text-danger error-message">This field is required.</span>`;
  let firstName = $("#firstName_").val();
  let lastName = $("#lastName_").val();
  let phone = $("#phone_").val();
  let email = $("#email_").val();
  let note = $("#message_").val();
  let token = $("#_csrf").val();

  if (!firstName && !firstName.trim()) {
    error = true;
    $("#firstName_").after(errMessage);
  }
  // if (!lastName && !lastName.trim()) {
  //   error = true;
  //   $("#lastName_").after(errMessage);
  // }
  if (!email && !email.trim()) {
    error = true;
    $("#email_").after(errMessage);
  }

  if (!error) {
    let loader = `<div class="spinner-border text-warning" role="status"></div>`;
    let currentBtn = this;
    $(this).attr("disabled", true);
    $(this).prepend(loader);
    $(this).css("opacity", "0.5");

    let data = {
      firstName,
      lastName,
      phone,
      email,
      note,
      _csrf: token,
    };
    $.ajax({
      type: "POST",
      url: `/enquiry-multiple`,
      data,
      success: function (response) {
        $(currentBtn).attr("disabled", false);
        $(currentBtn).css("opacity", "1");
        $(currentBtn).children().first().remove();
        if (response.success) {
          // console.log(response);
          // $(".product-enquiry-modal").modal("hide");
          location.href = `/thank-you?products=${response.products}`;
        } else {
          // console.log("something went wrong.");
        }
      },
      error: function (err) {
        $(currentBtn).attr("disabled", false);
        $(currentBtn).css("opacity", "1");
        $(currentBtn).children().first().remove();
        console.log(err);
      },
    });
  }
});

$(".header-search-btn").on("click", function () {
  let searchFor = $("#header-search").val();
  if (searchFor) {
    window.location = `/all-products?search=${searchFor}`;
  }
});
$(".middle-search-btn").on("click", function () {
  let searchFor = $("#middle-search").val();
  if (searchFor) {
    window.location = `/all-products?search=${searchFor}`;
  }
});

$(".remove-cookie-popup").on("click", function () {
  $(".cookie-card").remove();
});

$("#accept-cookie").on("click", function () {
  localStorage.setItem("cookie", "accept");
  location.reload();
});

if (localStorage.getItem("cookie")) {
  $(".cookie-card").remove();
}

let searchBtn = document.querySelector(".search-btn");
let searchBox = document.querySelector(".search-box");

document.body.addEventListener("click", (e) => {
  if (!document.querySelector(".search-box").contains(e.target)) {
    if (e.target === searchBtn.children[0] || e.target === searchBtn || e.target === searchBtn.children[1]) {
      searchBox.classList.toggle("_active");
    } else {
      searchBox.classList.remove("_active");
    }
  }
});

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    once: true,
    disable: "phone",
  });
});

Fancybox.bind("[data-fancybox]", {
  hideScrollbar: true,
  closeButton: true,
});
